<template>
  <div class="reports">
    <div class="tile">
      <ValidationObserver
        tag="form"
        v-slot="{ handleSubmit }"
        ref="uploadForm"
        class="mt-0"
      >
        <h5 class="tile-title mb-4">Upload Report (CSV)</h5>
        <div class="col-12">
          <ValidationProvider
            tag="div"
            rules="required"
            name="Type"
            v-slot="{ errors }"
            class="mt-3 mb-3"
          >
            <label for="type" class="form-label">Select Type:</label>
            <select
              v-model="apiRoute"
              :class="{ 'form-select': true, 'is-invalid': errors[0] }"
            >
              <option value="uploadIpCollaretal">IpCollateral</option>
              <option value="uploadIpCollaretalEMIR">IpCollateralEMIR</option>
            </select>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <ValidationProvider
          ref="file"
          tag="div"
          rules="required|mimes:csv"
          name="file"
          v-slot="{ errors }"
          class="mb-3"
        >
          <label for="file" class="col-form-label">CSV File:</label>
          <input
            id="uploadFile"
            class="form-control"
            type="file"
            @change="checkFile"
            :class="{
              'form-control': true,
              'is-invalid': errors[0],
            }"
          />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="d-grid d-md-flex justify-content-md-end">
          <button
            class="btn btn-secondary me-md-2"
            type="button"
            @click.prevent="resetForm"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-primary me-md-2"
            @click.prevent="handleSubmit(uploadFile)"
          >
            Upload
            <loader :isLoading="isLoading"></loader>
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mutations } from "../store";
import loader from "../components/loader";
export default {
  data() {
    return {
      apiRoute: "",
      selectedFile: {
        title: "",
        file: null,
      },
      isLoading: false,
    };
  },
  components: {
    loader,
  },
  methods: {
    async checkFile(event) {
      const { valid } = await this.$refs.file.validate(event);
      if (valid) {
        let file = event.target.files[0] || event.dataTransfer.files;
        this.selectFile(file);
      }
    },
    selectFile(file) {
      this.selectedFile.file = file;
      this.selectedFile.title = file.name.replace(/\.csv$/, "");
    },
    uploadFile() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("title", this.selectedFile.title);
      formData.append("file", this.selectedFile.file);
      this.$axios
        .post(`reports/${this.apiRoute}`, formData)
        .then(({ data: { success, message } }) => {
          this.resetForm();
          this.isLoading = false;
          mutations.showToast({ success, message });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetForm() {
      this.$nextTick(() => {
        this.apiRoute = "";
        this.selectedFile = {
          title: "",
          file: null,
        };
        this.$refs.uploadForm.reset();
        document.getElementById("uploadFile").value = null;
      });
    },
  },
};
</script>
